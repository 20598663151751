import React from 'react';
import ReactRegionSelect from 'react-region-select';

const StaticRegionImage = ({ imgSrc, region }) => {
    const regions = [{
        isChanging: false,
        height: region.height,
        width: region.width,
        x: region.x,
        y: region.y,
        new: false,
        data: { index: 0 },
    }];

    const regionStyle = {
        background: 'rgba(0, 255, 0, 0.25)'
    };

    const handleRegionChange = () => {
    };

    return (
      <ReactRegionSelect
        maxRegions={1}
        regions={regions}
        regionStyle={regionStyle}
        onChange={handleRegionChange}
        style={{
          touchAction: "none",
          display: "flex"
        }}
        >
        <img
          id="imageDisplay"
          style={{
            width: "100%"
          }}
          src={imgSrc}
          alt="sample">
        </img>
      </ReactRegionSelect>
    );
};

export default StaticRegionImage;