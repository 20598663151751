import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import TopAppBar from '../features/navigation/TopAppBar';
import { lightGreen, red, grey } from '@mui/material/colors';
import { Box } from '@mui/material';

const themeLight = createTheme({
  palette: {
    background: {
      default: "#f2f2f2"
    },
    good: {
      main: lightGreen[100],
    },
    goodBold: {
      main: lightGreen[900],
    },
    bad: {
      main: red[100],
    },
    badBold: {
      main: red[900],
    },
    neutral: {
      main: grey[300],
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const AppWrapper = () => {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />

      <Router>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
          }}
        >
          <TopAppBar />
          <App />
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default AppWrapper;
