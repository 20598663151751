import { useState, useEffect } from 'react';

import { useMediaQuery, Container, IconButton, Modal, List, ListItem, ListItemAvatar, Paper, Stack, Typography, Divider, Button, Tooltip } from "@mui/material";
import { LockReset } from '@mui/icons-material';

import UserAvatar from '../../components/UserAvatar';
import ChangePasswordModal from './ChangePasswordModal';

import { useTheme } from '@mui/material/styles';

import usePageTitle from "../navigation/PageTitle";

import { withSnackbar } from '../../components/SnackbarHOC';

import { useSelector, useDispatch } from 'react-redux';
import { selectUsers, fetchUsers, resetUserPassword, createUser } from './userManagementSlice';
import AddUserModal from './AddUserModal';

const UserManager = (props) => {
    usePageTitle('Manage Users');

    const dispatch = useDispatch();
    const users = useSelector(selectUsers);

    const theme = useTheme();
    const qMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [userDialogOpen, setUserDialogOpen] = useState(false);

    const [generatedPassword, setGeneratedPassword] = useState(null);

    const capitalize = (s) => {
        return s[0].toUpperCase() + s.slice(1);
    }

    const generatePassword = () => Math.random().toString(36).slice(-8);

    const handleAddNewUserClick = () => {
        setGeneratedPassword(generatePassword());
        setUserDialogOpen(true);
    };

    const handleAddNewUserAction = async (userData) => {
        setUserDialogOpen(false);

        try {
            await dispatch(createUser(userData)).unwrap();

            props.snackbarShowMessage('User created');
        } catch (err) {
            const msg = err.data?.message ?? 'Could not create user';
            props.snackbarShowMessage(msg, 'error');
        }
    };

    const handleUserPasswordResetClick = (user) => {
        setSelectedUser(user);
        setGeneratedPassword(generatePassword());
        setPasswordDialogOpen(true);
    };

    const handleUserPasswordResetAction = async () => {
        setPasswordDialogOpen(false);

        if (!selectedUser) {
            props.snackbarShowMessage('Could not reset user password', 'error');
        }

        try {
            await dispatch(resetUserPassword({ userId: selectedUser.id, password: generatedPassword })).unwrap();
            props.snackbarShowMessage('User password reset');
        } catch (err) {
            props.snackbarShowMessage('Could not reset user password', 'error');
        }
    }

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch, fetchUsers])

    return (
        <Container sx={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Modal open={passwordDialogOpen}>
                <ChangePasswordModal
                    password={generatedPassword}
                    onReset={handleUserPasswordResetAction}
                    onClose={() => setPasswordDialogOpen(false)}
                />
            </Modal>

            <Modal open={userDialogOpen}>
                <AddUserModal
                    snackbarShowMessage={props.snackbarShowMessage}
                    password={generatedPassword}
                    onSubmit={handleAddNewUserAction}
                    onClose={() => setUserDialogOpen(false)}
                />
            </Modal>

            <Stack
                direction="column"
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    mt={4}
                    mb={4}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewUserClick}
                    >
                        Create new user
                    </Button>
                </Stack>

                <Paper>
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper'
                        }}
                    >
                        <ListItem key="title" alignItems="center" sx={{ height: 75 }}>
                            <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
                                <Typography variant="h4" >Users</Typography>
                            </Stack>
                        </ListItem>
                        {users.map(user => (
                            <>
                                <Divider key={user.userName + "divider"} />
                                <ListItem
                                    key={user.userName}
                                    alignItems="center"
                                    secondaryAction={
                                        <Tooltip title="Reset password">
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleUserPasswordResetClick(user)}
                                                sx={{ marginLeft: 10 }}
                                            >
                                                <LockReset />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    sx={{
                                        height: 75
                                    }}
                                >
                                    <ListItemAvatar sx={{ width: 40, marginRight: 5, marginBottom: 0, marginTop: 0 }}>
                                        <UserAvatar user={user} />
                                    </ListItemAvatar>
                                    <Stack spacing={4} direction="row" alignItems="center" sx={{ height: "100%", paddingRight: 5 }}>
                                        {!qMobile && (
                                            <>
                                                <Typography variant="h6" sx={{ width: 100 }}>{user.firstName}</Typography>
                                                <Typography variant="h6" sx={{ width: 100 }}>{user.lastName}</Typography>
                                            </>
                                        )}

                                        {qMobile && (
                                            <Typography variant="h6" sx={{ width: 100 }}>{user.userName}</Typography>
                                        )}

                                        <Typography variant="h6"
                                            sx={{
                                                width: 100,
                                            }}
                                        >{capitalize(user.role)}</Typography>
                                    </Stack>
                                </ListItem>
                            </>
                        ))}
                    </List>
                </Paper>
            </Stack>
        </Container>
    );
}

export default withSnackbar(UserManager);