import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';

const initialState = {
    user: {
        userId: null,
        role: null,
        loggedIn: false,
        userName: null,
        firstName: null,
        lastName: null,
        token: null,
        resetPassword: null
    },
    status: 'idle',
    error: null
};

export const logIn = createAsyncThunk('user/logIn', async ({ username, password }) => {
    const response = await axios.post('api/auth/login', {
        username: username,
        password: password
    });
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loggedOut(state, action) {
            state.user.loggedIn = false;
            
            state.user.role = null;
            state.user.userName = null;
            state.user.token = null;
            state.user.userId = null;
            state.user.resetPassword = null;

            // Clear token in local storage
            localStorage.removeItem('token');

            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('userName');
        },
        loadUserDataOnStartup(state, action) {
            const token = localStorage.getItem('token');

            if (!token)
                return;

            const firstName = localStorage.getItem('firstName');
            const lastName = localStorage.getItem('lastName');
            const userName = localStorage.getItem('userName');

            const data = jwt(token);

            state.user.role = data.role;
            state.user.userId = data.sub;
            state.user.resetPassword = data.resetPass;
            state.user.firstName = firstName;
            state.user.lastName = lastName;
            state.user.userName = userName;

            // Assume logged in if there is a token
            state.user.loggedIn = true;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(logIn.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(logIn.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.user.loggedIn = true;
                state.user.token = action.payload.token;

                // Decode token and store information
                const data = jwt(action.payload.token);
                state.user.role = data.role;
                state.user.userId = data.sub;
                state.user.resetPassword = data.resetPass;
                state.user.firstName = action.payload.firstName;
                state.user.lastName = action.payload.lastName;
                state.user.userName = action.payload.firstName;

                // Store token in localStorage
                localStorage.setItem('token', state.user.token);
                localStorage.setItem('firstName', action.payload.firstName);
                localStorage.setItem('lastName', action.payload.lastName);
                localStorage.setItem('userName', action.payload.userName);
            })
            .addCase(logIn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectCurrentUser = state => state.user.user;
export const selectToken = state => state.user.user.token;

export const { loggedOut, loadUserDataOnStartup } = userSlice.actions;

export default userSlice.reducer;