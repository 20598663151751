import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import StaticRegionImage from './StaticRegionImage';

const ImageLabelerItem = (props) => {
    const { sample, setLabel, onSubmit } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const badVariant = sample.label === 'bad' ? 'contained' : 'text';
    const goodVariant = sample.label === 'good' ? 'contained' : 'text';

    const title = `Weld #${sample.id}`;
    const nameTag = sample.user.name[0].toUpperCase() + sample.user.surname[0].toUpperCase();

    const date = new Date(sample.createdAt).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const submitDisabled = (sample.label === 'bad' || sample.label === 'good') ? false : true;

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const open = Boolean(menuAnchorEl);

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={title}
                subheader={date}
                avatar={
                    <Tooltip title={sample.user.username} placement="top">
                        <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                            { nameTag }
                        </Avatar>
                    </Tooltip>
                }
                action={
                    <IconButton aria-label="settings" onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </IconButton>
                }
            >
            </CardHeader>
            <Menu
                id="image-labeler-item-menu"
                anchorEl={menuAnchorEl}
                open={open}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>Report region incorrect</MenuItem>
            </Menu>
            <CardMedia>
                <StaticRegionImage imgSrc={sample.imgUrl} region={sample.region} />
            </CardMedia>
            <CardActions disableSpacing>
                <Button
                    variant={badVariant}
                    color="error"
                    sx={{ ml: 1 }}
                    onClick={() => { setLabel('bad'); }}>
                    Bad
                </Button>
                <Button
                    variant={goodVariant}
                    color="success"
                    onClick={() => { setLabel('good'); }}>
                    Good
                </Button>
                <Box
                    sx={{ flexGrow: 1 }}
                ></Box>
                <Button
                    variant="outlined"
                    disabled={submitDisabled}
                    color="primary"
                    sx={{ m: 1 }}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </CardActions>
        </Card>
    );
};

export default ImageLabelerItem;