import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

const ModelOutputOptions = ({ title, modelOutputFilter, onUpdate }) => {
    const goodProps = modelOutputFilter === 'good' ? { color: 'primary' } : {};
    const badProps = modelOutputFilter === 'bad' ? { color: 'primary' } : {};

    const handleClick = (button) => {
        if (button === 'good') {
            onUpdate(modelOutputFilter === 'good' ? null : 'good');
        } else if (button === 'bad') {
            onUpdate(modelOutputFilter === 'bad' ? null : 'bad');
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 0,
                width: 100,
                marginBottom: 2,
                marginTop: 2,
            }}
        >
            <Typography variant="body1" color="text.secondary"
                sx={{
                    breakAfter: 'always',
                }}
            >
                {title}
            </Typography>
            <Tooltip title="Only good welds">
                <IconButton arial-label="class-good" onClick={() => handleClick('good')} {...goodProps}>
                    <CheckCircle />
                </IconButton>
            </Tooltip>
            <Tooltip title="Only bad welds">
                <IconButton arial-label="class-bad" onClick={() => handleClick('bad')} {...badProps}>
                    <Cancel />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ModelOutputOptions;