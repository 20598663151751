import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageTitle: null,
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        pageTitleUpdated(state, action) {
            state.pageTitle = action.payload;
        }
    }
});

export const selectPageTitle = state => state.navigation.pageTitle;

export const { pageTitleUpdated } = navigationSlice.actions;

export default navigationSlice.reducer;