import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

import StaticRegionImage from './StaticRegionImage';
import { CardContent, Divider, LinearProgress, Stack, Typography } from '@mui/material';

const ImageLabelledItem = ({ sample }) => {
    const title = `Weld #${sample.id}`;
    const nameTag = sample.user.name[0].toUpperCase() + sample.user.surname[0].toUpperCase();

    const date = new Date(sample.createdAt).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    let perc = 100;
    if ((sample.numBad + sample.numBad) > 0) {
        perc = sample.numGood / (sample.numGood + sample.numBad) * 100;
    }

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={title}
                subheader={date}
                avatar={
                    <Tooltip title={sample.user.username} placement="top">
                        <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                            { nameTag }
                        </Avatar>
                    </Tooltip>
                }
            >
            </CardHeader>
            <CardMedia>
                <StaticRegionImage imgSrc={sample.imgUrl} region={sample.region} />
            </CardMedia>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <Stack direction="row">
                        {/* <DoneIcon /> */}
                        <CheckCircle color="goodBold"/>
                        <Typography ml={1} sx={{fontWeight: 'bold', color: 'goodBold.main'}}>
                            {sample.numGood}
                        </Typography>
                    </Stack>
                    <Stack direction="row">
                        <Cancel color="badBold"/>
                        <Typography ml={1} sx={{fontWeight: 'bold', color: 'badBold.main'}}>
                            {sample.numBad}
                        </Typography>
                    </Stack>
                </Stack>
                <LinearProgress
                    variant="determinate"
                    value={perc}
                    color="good"
                    sx={{
                        marginTop: 2,
                        height: 6,
                        marginLeft: '-16px',
                        marginRight: '-16px',
                        marginBottom: '-24px',
                        backgroundColor: 'bad.main',
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default ImageLabelledItem;