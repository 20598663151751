import { useState } from 'react';

import { useMediaQuery, Menu, MenuItem, Stack, Divider, Box, List, ListItem, ListItemButton, ListItemText, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography'

import { Link } from 'react-router-dom';

import UserAvatar from '../../components/UserAvatar';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser, loggedOut } from '../../components/userSlice';
import { selectPageTitle } from './navigationSlice';

const allMenuItems = {
    'student': [
        { text: 'New weld', to: '/weld/new' },
        { text: 'View welds', to: '/weld/view' },
    ],
    'supervisor': [
        { text: 'New weld', to: '/weld/new' },
        { text: 'Label welds', to: '/weld/label' },
        { text: 'View welds', to: '/weld/view' },
    ],
    'instructor': [
        { text: 'New weld', to: '/weld/new' },
        { text: 'Label welds', to: '/weld/label' },
        { text: 'View welds', to: '/weld/view' },
        { text: 'Manage users', to: '/users' },
    ],
};

export default function TopAppBar() {
    const dispatch = useDispatch();

    const theme = useTheme();
    const qMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const user = useSelector(selectCurrentUser);
    const pageTitle = useSelector(selectPageTitle);

    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const menuItems = user.role ? allMenuItems[user.role] : [];

    const mobileProps = qMobile ? { flexDirection: 'column-reverse' } : {};

    const handleUserMenu = (event) => {
        setUserAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserAnchorEl(null);
    };

    const handleLogoutClick = () => {
        handleUserMenuClose();
        dispatch(loggedOut());
    };

    return (
        <>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setDrawerOpen(false)}
                    onKeyDown={() => setDrawerOpen(false)}
                >
                    <List>
                        {menuItems.map(({text, to}) => (
                            <ListItem button key={text} component={Link} to={to}>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>

                    {qMobile &&
                    <>
                    <Divider />
                    <List>
                        <ListItemButton onClick={handleLogoutClick}>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </List>
                    </>}
                </Box>
            </Drawer>
            <AppBar position="static">
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        {...mobileProps}
                    >
                        <Stack direction="row" alignItems="center" sx={{ minWidth: 175 }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => setDrawerOpen(true)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Typography variant="h6" component="div">
                                { pageTitle }
                            </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                            <img style={{maxWidth: '100px'}} src="/csir_logo.png" alt="csir logo"></img>
                            <img style={{maxWidth: '100px'}} src="/saiw_logo.png" alt="saiw logo"></img>
                        </Stack>

                        {!qMobile && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                minWidth: '175px',
                            }}
                        >
                            {user.loggedIn && (
                                <>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleUserMenu}
                                        color="inherit"
                                    >
                                        <UserAvatar user={user} />
                                    </IconButton>

                                    <Menu
                                        id="menu-appbar-user"
                                        anchorEl={userAnchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(userAnchorEl)}
                                        onClose={handleUserMenuClose}
                                    >
                                        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                                    </Menu>
                                </>
                            )}
                        </Box>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    )
}