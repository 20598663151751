import axios from 'axios';

import { store } from './app/store';

import { loggedOut } from './components/userSlice';

const api = () => {
    const axiosInstance = axios.create();

    const token = localStorage.getItem('token');

    axiosInstance.interceptors.request.use((config) => {
        if (token !== null) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            const { status } = error.response;

            if (status === 401) {
                store.dispatch(loggedOut())
            }

            return Promise.reject(error);
        }
    )

    axiosInstance.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    }

    return axiosInstance;
};

export default api;