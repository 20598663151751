import { useEffect, useState } from 'react';
import { ArrowDownward, ArrowUpward, Cancel, CheckCircle, Event, FilterAlt, Numbers, Rule } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Autocomplete, Box, Button, Container, Divider, Grid, IconButton, Pagination, Paper, Stack, TextField, Tooltip, Typography, Modal } from "@mui/material";
import usePageTitle from "../navigation/PageTitle";
import axios from '../../api';

import { useSelector, useDispatch } from 'react-redux';
import { selectUsers, fetchUsers } from '../usermanagement/userManagementSlice';
import WeldItem from './WeldItem';
import { blueGrey } from '@mui/material/colors';

import { selectCurrentUser } from '../../components/userSlice';

import FilterBar from './FilterBar';
import FilterModal from './FilterModal';

const pageSize = 9;

const WeldViewer = () => {
    usePageTitle('View Welds');

    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const user = useSelector(selectCurrentUser);

    const theme = useTheme();
    const qMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const [welds, setWelds] = useState([]);
    const [totalWelds, setTotalWelds] = useState(0);
    const [page, setPage] = useState(1);
    const [filterOpts, setFilterOpts] = useState(null);
    const [filterModalOpen, setFilterModalOpen] = useState(false);

    const pageCount = totalWelds < pageSize ? 1 : Math.trunc(totalWelds / pageSize);

    const handleFilter = () => {
        setPage(1);
        fetchWelds(true);
    };

    const fetchWelds = async (filterUpdate) => {
        let qp = {
            limit: pageSize,
            offset: filterUpdate ? 0 : (page - 1) * pageSize
        };

        if (filterOpts?.sort) {
            qp.sort = filterOpts.sort;
        }

        if (filterOpts?.modelOutputFilter) {
            qp.modelClassification = filterOpts.modelOutputFilter;
        }

        if (filterOpts?.labeler) {
            qp.labeler = filterOpts.labeler;
        }

        if (filterOpts?.uploader) {
            qp.uploader = filterOpts.uploader;
        }

        try {
            const response = await axios().get('/api/samples', { params: qp });
            setTotalWelds(response.data.count);
            setWelds(response.data.samples);

            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    useEffect(() => {
        fetchWelds();
        dispatch(fetchUsers());
    }, []);

    useEffect(() => {
        fetchWelds();
    }, [page]);

    let filterBar = (
        <FilterBar
            userRole={user.role}
            users={users}
            onUpdate={setFilterOpts}
            onFilter={handleFilter}
        />
    );

    if (qMobile) {
        filterBar = (
            <Modal open={filterModalOpen}>
                <FilterModal
                    userRole={user.role}
                    users={users}
                    setModalOpen={setFilterModalOpen}
                    onUpdate={setFilterOpts}
                    onFilter={handleFilter}
                />
            </Modal>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Stack
                direction="column"
                justifyContent="center"
                mt={4}
                sx={{
                    breakAfter: 'always',
                }}
            >
                {filterBar}

                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    mt={2}
                >
                    <Pagination
                        count={pageCount}
                        page={page}
                        onChange={(e, v) => setPage(v)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    />

                    {qMobile && <IconButton aria-label="filter" onClick={() => setFilterModalOpen(true)}>
                        <FilterAlt />
                    </IconButton>}
                </Stack>
            </Stack>
            <Container>
                <Grid
                    container
                    justifyContent="center"
                    justifyItems="center"
                    spacing={2}
                    mt={4}
                    mb={4}
                >

                    {welds.map(w => (
                        <Grid key={w.id} item md={4}>
                            <WeldItem
                                sample={w}
                                showLabels={user.role !== 'student'}
                                showLabelers={user.role === 'instructor'}
                            />
                        </Grid>
                    ))}

                    {welds.length === 0 && <Typography
                        variant="h4"
                        color={blueGrey[700]}
                        sx={{
                            fontWeight: 'bold',
                            mt: 4,
                            textAlign: 'center',
                        }}
                    >
                        No results.
                    </Typography>}
                </Grid>
            </Container>
        </Box>
    );
};

export default WeldViewer;