import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { store } from './app/store';

import AppWrapper from './app/AppWrapper';

ReactDOM.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.querySelector('#root')
);
