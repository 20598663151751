import { Container, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import usePageTitle from "../features/navigation/PageTitle";

const NotFound = () => {
    usePageTitle('');

    return (
        <Container>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                minHeight: "90vh"
            }}
        >
            <Typography
                variant="h3"
                color={blueGrey[700]}
                sx={{
                    fontWeight: 'bold',
                    mb: 3
                }}
            >
                Whoops.
            </Typography>
            <Typography
                color={blueGrey[700]}
                variant="h6"
            >
                We couldn't find what you were looking for.
            </Typography>

        </Stack>
        </Container>
    );
};

export default NotFound;