import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = (props) => {
    const { config, user } = props;

    let requireRole;
    if (config?.requireRole) {
        requireRole = Array.isArray(config.requireRole) ? config.requireRole : [config.requireRole];
    }

    let autoRedirForReset = config?.autoRedirForReset ?? true;

    let location = useLocation();

    if (autoRedirForReset && user.resetPassword && user.loggedIn) {
        return <Navigate to='/password/change' state={{ from: location }} />;
    }

    if (config.requireAuth && !user.loggedIn) {
        return <Navigate to="/login" state={{ from: location }}/>;
    }

    if (requireRole && !requireRole.includes(user.role)) {
        const redirTo = config.roleRedirect ? config.roleRedirect : '/404';

        return <Navigate to={redirTo} state={{ from: location }}/>;
    }

    return <Outlet />;
};

export default ProtectedRoute;