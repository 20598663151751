import axios from '../../api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    users: [],
    status: 'idle',
    error: null,
}

export const resetUserPassword = createAsyncThunk('userManagement/resetPassword', async ({userId, password}) => {
    const response = await axios().post(`/api/users/${userId}/password/reset`, {
        password: password
    });

    return response.data;
});

export const fetchUsers = createAsyncThunk('userManagement/fetchUsers', async () => {
    const response = await axios().get('/api/users/');

    return response.data;
});

export const createUser = createAsyncThunk('userManagement/createUser', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios().post('/api/users', { user: userData });

        return response.data;
    } catch (err) {
        if (!err.response) {
            throw err;
        }

        const d = {
            status: err.response.status,
            data: err.response.data,
        };
        return rejectWithValue(d);
    }
})

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(resetUserPassword.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(resetUserPassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(resetUserPassword.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message;
            })
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createUser.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.users.push(action.payload);
            })
            .addCase(createUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectUsers = state => state.userManagement.users;

export default userManagementSlice.reducer;