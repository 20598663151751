import { Paper } from "@mui/material";
import Filters from "./Filters";

const FilterBar = ({ userRole, users, onUpdate, onFilter }) => {
    return (
        <Paper
            sx={{
                display: 'flex'
            }}
        >
            <Filters
                userRole={userRole}
                users={users}
                onUpdate={onUpdate}
                onFilter={onFilter}
            />
        </Paper>
    );
};

export default FilterBar;