import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import { useDispatch } from 'react-redux';

import { loggedOut, selectCurrentUser } from './userSlice';
import { useSelector } from 'react-redux';

import { withSnackbar } from './SnackbarHOC';

import axios from '../api';
import usePageTitle from '../features/navigation/PageTitle';

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    usePageTitle('Change Password');

    const user = useSelector(selectCurrentUser);

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const handleSubmit = async () => {
        if (!password1) {
            props.snackbarShowMessage('Password cannot be empty', 'warning');
            return;
        }

        if (password1 !== password2) {
            props.snackbarShowMessage('Passwords do not match', 'warning');
            return;
        }

        if (password1.length < 10) {
            props.snackbarShowMessage('Password must be at least 10 characters long', 'warning');
            return;
        }

        try {
            await axios().post('/api/auth/password/update', {
                password: password1
            });

            props.snackbarShowMessage('Password updated');
            dispatch(loggedOut());
        } catch (err) {
            console.log('Could not update password', err);
            props.snackbarShowMessage('Could not update password', 'error');
        }
    };

    const from = location.state?.from?.pathname || '/';
    useEffect(() => {
      if (!user.resetPassword) {
        navigate(from , { replace: true });
      }
    }, [user, navigate, from]);

    return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
      }}>
      <Paper elevation={3} sx={{mb: 0.5}}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            textAlign="center"
            sx={{ m: 2 }}>
            Enter new password
          </Typography>

          <Divider style={{ width: '100%' }} />

          <TextField
            label="Password"
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            variant="standard"
            sx={{
                ml: 2, mr: 2, mt: 2, mb: 1
            }}
            />
          <TextField
            label="Password confirmation"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            onKeyPress={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            variant="standard"
            sx={{
                ml: 2, mr: 2, mt: 2, mb: 1
            }}
            />

          <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2, mb: 2 }}>Submit</Button>
        </Box>
      </Paper>
    </Box>
    );
}

export default withSnackbar(ChangePassword);