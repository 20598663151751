import React from 'react';

import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";

const ChangePasswordModal = React.forwardRef((props, ref) => {
    const { onReset, onClose, password } = props;
    return (
        <Box
            ref={ref}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Card sx={{ width: 300 }}>
                <CardContent>
                    <Typography gutterBottom align="center" variant="h5" component="div">
                        Password reset
                    </Typography>
                    <Typography align="center" variant="body2" color="text.secondary">
                        The user's password will be set to the following password.
                    </Typography>
                    <Typography align="center" variant="body1" mt={2} mb={2} sx={{ fontSize: "18px" }}>
                        {password}
                    </Typography>
                    <Typography align="center" variant="body2" color="text.secondary">
                        They will be forced to change their password on their next login.
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button size="small" onClick={onReset}>Reset</Button>
                    <Button size="small" onClick={onClose}>Cancel</Button>
                </CardActions>
            </Card>
        </Box>
    );
});

export default ChangePasswordModal;