import React, { useEffect, useState } from 'react';

import { Box, Button, Card, CardActions, CardContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

const AddUserModal = React.forwardRef((props, ref) => {
    const { onSubmit, onClose, password, snackbarShowMessage } = props;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [role, setRole] = useState('');

    const checkValid = () => {
        if (firstName.length < 3) {
            snackbarShowMessage('First name needs to be at least 4 characters.', 'warning');
            return false;
        }

        if (lastName.length < 3) {
            snackbarShowMessage('Last name needs to be at least 4 characters.', 'warning');
            return false;
        }

        if (userName.length < 4) {
            snackbarShowMessage('Username needs to be at least 4 characters.', 'warning');
            return false;
        }

        if (password.length < 4) {
            snackbarShowMessage('Generated password invalid', 'error');
            return false;
        }

        if (!['student', 'supervisor', 'instructor'].includes(role)) {
            snackbarShowMessage('Please select a role', 'warning');
            return false;
        }

        return true;
    }

    const handleCreate = () => {
        if (!checkValid())
            return;

        onSubmit({
            firstName: firstName,
            lastName: lastName,
            userName: userName,
            role: role,
            password: password,
        });
    };

    useEffect(() => {
        let uname = '';

        if (firstName && firstName.length > 0)
            uname += firstName[0].toUpperCase();

        if (lastName && lastName.length > 1)
            uname += lastName[0].toUpperCase() + lastName.slice(1);

        setUserName(uname);
    }, [firstName, lastName]);

    return (
        <Box
            ref={ref}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Card sx={{ width: 300 }}>
                <CardContent>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Typography gutterBottom align="center" variant="h5" component="div">
                            User Creation
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />

                        <TextField
                            label="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            variant="standard"
                        />

                        <TextField
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant="standard"
                        />

                        <TextField
                            label="Username"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            variant="standard"
                        />

                        <FormControl fullWidth>
                            <InputLabel id="user-role-select">Role</InputLabel>
                            <Select
                                labelId="user-role-select"
                                label="Role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem value="student">Student</MenuItem>
                                <MenuItem value="supervisor">Supervisor</MenuItem>
                                <MenuItem value="instructor">Instructor</MenuItem>
                            </Select>
                        </FormControl>

                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                        <Typography align="center" variant="body2" color="text.secondary">
                            The user's password will be set to the following password.
                        </Typography>
                        <Typography align="center" variant="body1" mt={2} mb={2} sx={{ fontSize: "18px" }}>
                            {password}
                        </Typography>
                        <Typography align="center" variant="body2" color="text.secondary">
                            They will be forced to change their password on first login.
                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button size="small" onClick={handleCreate}>Create User</Button>
                    <Button size="small" onClick={onClose}>Cancel</Button>
                </CardActions>
            </Card>
        </Box>
    );
});

export default AddUserModal;