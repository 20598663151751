import { Avatar, Tooltip } from "@mui/material";

const UserAvatar = ({ user }) => {
    const lettersToColor = (letters, s, l) => {
        let hash = 0;
        let i;

        for (i = 0; i < letters.length; i++) {
            hash = letters.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }

        return color;
    };

    const getAvatarLetters = (user) => {
        return user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase();
    };

    const letters = getAvatarLetters(user);
    const color = lettersToColor(user.firstName + user.lastName);

    return (
        <Tooltip title={user.userName} placement="top">
            <Avatar
                alt={letters}
                sx={{
                    bgcolor: color,
                }}>
                {letters}
            </Avatar>
        </Tooltip>
    );
};

export default UserAvatar;