import { configureStore } from '@reduxjs/toolkit'

import userReducer from '../components/userSlice'
import navigationReducer from '../features/navigation/navigationSlice';
import userManagementReducer from '../features/usermanagement/userManagementSlice';

export const store =  configureStore({
    reducer: {
        user: userReducer,
        navigation: navigationReducer,
        userManagement: userManagementReducer,
    }
});