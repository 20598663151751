import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser, loadUserDataOnStartup } from '../components/userSlice';

import ImageInference from '../components/ImageInference';
import ImageLabeler from '../components/ImageLabeler';
import LogIn from '../components/LogIn';
import ChangePassword from '../components/ChangePassword';
import NotFound from '../components/NotFound';
import WeldViewer from '../features/weldviewing/WeldViewer';
import UserManager from '../features/usermanagement/UserManager';

import ProtectedRoute from '../features/navigation/ProtectedRoute';
import { Routes, Route, Navigate } from 'react-router-dom';

const App = () => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserDataOnStartup());
  }, [dispatch])

  const rootPath = user.role === 'student' ? '/weld/new' : '/weld/label';

  return (
    <Routes>
      {/* Password reset route */}
      <Route
        element={<ProtectedRoute user={user} config={{
          requireAuth: true,
          autoRedirForReset: false,
        }}/>}
      >
        <Route path="/password/change" element={<ChangePassword />} />
      </Route>

      <Route exact path='/login' element={<LogIn />} />

      {/* Student routes */}
      <Route
        element={<ProtectedRoute user={user} config={{
          requireAuth: true,
        }}/>}
      >
        <Route path="/weld/new" element={<ImageInference />} />

        <Route path="/weld/view" element={<WeldViewer />} />
      </Route>


      {/* Instructor + Supervisor routes */}
      <Route
        element={<ProtectedRoute user={user} config={{
          requireAuth: true,
          requireRole: ['supervisor', 'instructor'],
        }}/>}
      >
        <Route path="/weld/label" element={<ImageLabeler />} />
      </Route>

      {/* Instructor routes */}
      <Route
        element={<ProtectedRoute user={user} config={{
          requireAuth: true,
          requireRole: 'instructor',
        }}/>}
      >
        <Route path="/users" element={<UserManager />} />
      </Route>

      <Route exact path='/' element={<Navigate to={rootPath} />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default App;
