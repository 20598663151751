import { useState, useEffect } from 'react';
import { Paper, TextField, Stack, Divider, Autocomplete, Button } from '@mui/material';

import SortOptions from './SortOptions';
import ModelOutputOptions from './ModelOutputOptions';

const Filters = ({ stackDirection, hideButton, userRole, users, onUpdate, onFilter }) => {
    const [modelOutputFilter, setModelOutputFilter] = useState(null);
    const [sortOptions, setSortOptions] = useState(null);
    const [labeler, setLabeler] = useState(null);
    const [uploader, setUploader] = useState(null);

    const labelerUsers = users.filter(u => u.role !== 'student');
    const uploaderUsers = users.filter(u => u.role === 'student');

    const constructOptions = () => {
        return {
            sort: sortOptions,
            modelOutputFilter: modelOutputFilter,
            labeler: labeler,
            uploader: uploader,
        }
    };

    useEffect(() => {
        onUpdate(constructOptions);
    }, [modelOutputFilter, sortOptions, labeler, uploader]);

    return (
        <Stack
            direction={stackDirection ?? "row"}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={stackDirection == "column" ? 1 : 3}
            sx={{
                paddingLeft: 3,
                paddingRight: 3
            }}
            alignItems="center"
        >
            {(userRole === 'supervisor' || userRole === 'instructor') && <Autocomplete
                id="combo-box-uploader"
                options={uploaderUsers}
                getOptionLabel={o => `${o.firstName} ${o.lastName}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, v) => setUploader(v?.id)}
                renderInput={(params) => <TextField {...params} label="Uploader" />}
                sx={{
                    width: 200,
                    marginBottom: 3,
                    marginTop: 3,
                }}
            />}

            {userRole === 'instructor' && <Autocomplete
                id="combo-box-labeler"
                options={labelerUsers}
                getOptionLabel={o => `${o.firstName} ${o.lastName}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, v) => setLabeler(v?.id)}
                renderInput={(params) => <TextField {...params} label="Labeler" />}
                sx={{
                    width: 200,
                    marginBottom: 2,
                    marginTop: 2,
                }}
            />}

            <ModelOutputOptions
                title={userRole === 'student' ? 'Grading' : 'Model output'}
                modelOutputFilter={modelOutputFilter}
                onUpdate={setModelOutputFilter}
            />

            <SortOptions
                showDate={true}
                showLabels={userRole === 'supervisor' || userRole === 'instructor'}
                showDiscrepancies={userRole === 'supervisor' || userRole === 'instructor'}
                sortOptions={sortOptions}
                onUpdate={setSortOptions}
            />

            {!hideButton &&
                <Button variant="text" onClick={() => onFilter(true)}>Filter</Button>}
        </Stack>
    );
};

export default Filters;