import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import { useDispatch } from 'react-redux';

import { logIn, selectCurrentUser } from './userSlice';
import { useSelector } from 'react-redux';

import { withSnackbar } from './SnackbarHOC';
import usePageTitle from '../features/navigation/PageTitle';

const LogIn = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    usePageTitle('Log In');

    const user = useSelector(selectCurrentUser);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async () => {
        try {
          await dispatch(logIn({ username, password })).unwrap();
        } catch (err) {
          console.log(err);

          props.snackbarShowMessage('Invalid username or password', 'error');
        }
    };

    const from = location.state?.from?.pathname || '/';
    useEffect(() => {
      if (user.loggedIn) {
        navigate(from , { replace: true });
      }
    }, [user, navigate, from])

    return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
      }}>
      <Paper elevation={3} sx={{mb: 0.5}}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 4,
            marginRight: 4,
          }}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            textAlign="center"
            sx={{ m: 2 }}>
            Log In
          </Typography>

          <Divider style={{ width: '100%' }} />

          <TextField
            label="Username"
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
                ml: 2, mr: 2, mt: 2, mb: 1
            }}
            />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => { if (e.key === 'Enter') handleSubmit(); }}
            variant="standard"
            sx={{
                ml: 2, mr: 2, mt: 2, mb: 1
            }}
            />

          <Button onClick={handleSubmit} variant="contained" sx={{ mt: 3, mb: 3 }}>Submit</Button>
        </Box>
      </Paper>
    </Box>
    );
}

export default withSnackbar(LogIn);