import { Box, Button, Card, CardActions, CardContent } from '@mui/material';
import React from 'react';

import Filters from './Filters';

const FilterModal = React.forwardRef((props, ref) => {
    const { setModalOpen, onFilter, ...otherProps } = props;

    const handleClose = (filter) => {
        setModalOpen(false);
        onFilter(filter);
    }

    return (
        <Box
            ref={ref}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Card sx={{ width: 300 }}>
                <CardContent>
                    <Filters
                        stackDirection="column"
                        hideButton={true}
                        {...otherProps}
                    />
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button size="small" onClick={() => handleClose(true)}>Filter</Button>
                    <Button size="small" onClick={() => handleClose(false)}>Close</Button>
                </CardActions>
            </Card>
        </Box>
    );
});

export default FilterModal;