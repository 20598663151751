import React, { useState, useEffect, useCallback } from 'react';

import axios from '../api';

import { useMediaQuery, Stack, Container, Button, Divider, Grid, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

import { withSnackbar } from './SnackbarHOC';

import ImageLabelerItem from './ImageLabelerItem';
import ImageLabelledItem from './ImageLabelledItem';
import usePageTitle from '../features/navigation/PageTitle';
import { Link as NavLink } from 'react-router-dom';

const ImageLabeler = (props) => {
  const theme = useTheme();
  const qMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const qLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  usePageTitle('Label Welds');

  const [noSamples, setNoSamples] = useState(false);

  let horizontalItems;
  if (qLg)
    horizontalItems = 3;
  else if (qMd)
    horizontalItems = 2;
  else
    horizontalItems = 1;

  const numSamples = horizontalItems * 2;

  const [samples, setSamples] = useState([]);

  const submitAllDisabled = samples.some(sample => (sample.label !== 'good' && sample.label !== 'bad')) || samples.filter(s => !s.saved).length === 0;

  const updateLabel = (index, label) => {
    setSamples(samples.map((s, i) => {
      if (i !== index)
        return s;

      return {
        ...s,
        label: label,
      };
    }));
  };

  const getUnlabelledSamples = useCallback(async () => {
    let response;
    try {
      response = await axios().get(`/api/samples/unlabelled/${numSamples}`);
    } catch (e) {
      console.log("next sample error: ", e);
      return;
    }

    let newSamples = response.data.map(s => {
      return {
        ...s,
        label: '',
        numGood: null,
        numBad: null,
        saved: false,
      }
    });

    setSamples(newSamples);

    if (newSamples.length === 0) {
      setNoSamples(true);
    } else {
      setNoSamples(false);
    }

  }, [numSamples]);

  const sendLabels = async (indexes) => {
    const data = indexes.map(i => {
      return {
        label: samples[i].label,
        id: samples[i].id
      };
    });

    try {
      const res = await axios().post(`/api/samples/label`, {
        sampleLabels: data,
      });

      let newSamples = samples.map((s, i) => {
        let index = res.data.findIndex(d => d.id === s.id);

        if (index === -1)
          return s;

        return {
          ...s,
          numGood: res.data[index].numGood,
          numBad: res.data[index].numBad,
          saved: true,
        };
      });

      setSamples(newSamples);

      props.snackbarShowMessage('Labels saved');
    } catch (e) {
      props.snackbarShowMessage('Could not save labels', 'error');
      console.log("send label error: ", e);
    }
  };

  const handleSubmitAll = async () => {
    let indexes = [];
    for (let i = 0; i < samples.length; i++) {
      if (!samples[i].saved)
        indexes.push(i);
    }

    await sendLabels(indexes);
  };

  useEffect(() => {
    getUnlabelledSamples();
  }, [getUnlabelledSamples]);

  if (noSamples) {
    return (
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "90vh"
          }}
        >
          <Typography
            variant="h4"
            color={blueGrey[700]}
            sx={{
              fontWeight: 'bold',
              mb: 3,
              ml: 1,
              mr: 1,
              textAlign: 'center',
            }}
          >
            No more unlabelled welds.
          </Typography>

          <Typography
            variant="h6"
            color={blueGrey[700]}
            sx={{
              fontWeight: 'bold',
              mb: 3,
              ml: 1,
              mr: 1,
              textAlign: 'center',
            }}
          >
            <Link component={NavLink} to='/weld/view' mr={0.8}>View</Link>
            welds instead.
          </Typography>

        </Stack>
      </Container>
    )
  }

  let sampleLabelerItems = samples.map((s, i) => {
    let inner;
    if (!s.saved) {
      inner = (
        <ImageLabelerItem
          sample={s}
          onSubmit={async () => { await sendLabels([i]) }}
          setLabel={l => { updateLabel(i, l) }}
        />
      );
    }
    else {
      inner = (
        <ImageLabelledItem sample={s} />
      );
    }
    return (
      <Grid key={s.id} item md={4}>
        {inner}
      </Grid>
    );
  });

  return (
    <Container>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="center"
        spacing={4}
        mt={4}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={submitAllDisabled}
          onClick={handleSubmitAll}
        >
          Submit All
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={getUnlabelledSamples}
        >
          Load next
        </Button>
      </Stack>

      <Grid
        container
        justifyContent="center"
        spacing={2}
        mt={4}
        mb={4}
      >
        {sampleLabelerItems}
      </Grid>
    </Container>
  );
};

export default withSnackbar(ImageLabeler);
