import { Box, Typography, Tooltip, IconButton } from '@mui/material';

import { ArrowDownward, ArrowUpward, Event, Numbers, Rule } from '@mui/icons-material';

const SortOptions = ({ sortOptions, onUpdate, showDate, showLabels, showDiscrepancies }) => {
    const dateProps = sortOptions?.includes('date') ? { color: 'primary' } : {};
    const labelsProps = sortOptions?.includes('labels') ? { color: 'primary' } : {};
    const discrepanciesProps = sortOptions?.includes('discrepancies') ? { color: 'primary' } : {};

    let dateIcon = <Event />;
    let labelsIcon = <Numbers />;
    let discrepanciesIcon = <Rule />;

    if (sortOptions) {
        if (sortOptions.includes('date')) {
            dateIcon = sortOptions.includes('-') ? <ArrowDownward /> : <ArrowUpward />;
        } else if (sortOptions.includes('labels')) {
            labelsIcon = sortOptions.includes('-') ? <ArrowDownward /> : <ArrowUpward />;
        } else if (sortOptions.includes('discrepancies')) {
            discrepanciesIcon = sortOptions.includes('-') ? <ArrowDownward /> : <ArrowUpward />;
        }
    }

    const handleClick = (button) => {
        let newOpt = null;
        if (button === 'date') {
            if (!sortOptions)
                newOpt = 'date';
            else if (sortOptions === 'date')
                newOpt = '-date';
            else if (sortOptions === '-date')
                newOpt = null
        }
        else if (button === 'labels') {
            if (!sortOptions)
                newOpt = 'labels';
            else if (sortOptions === 'labels')
                newOpt = '-labels';
            else if (sortOptions === '-labels')
                newOpt = null
        }
        else if (button === 'discrepancies') {
            if (!sortOptions)
                newOpt = 'discrepancies';
            else if (sortOptions === 'discrepancies')
                newOpt = '-discrepancies';
            else if (sortOptions === '-discrepancies')
                newOpt = null
        }

        onUpdate(newOpt);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 0,
                width: 120,
            }}
        >
            <Typography variant="body1" color="text.secondary"
                sx={{
                    breakAfter: 'always',
                }}
            >
                Sort
            </Typography>

            {showDate && <Tooltip title="Date">
                <IconButton arial-label="class-good" onClick={() => handleClick('date')} {...dateProps}>
                    {dateIcon}
                </IconButton>
            </Tooltip>}

            {showLabels && <Tooltip title="Number of labels">
                <IconButton arial-label="class-bad" onClick={() => handleClick('labels')} {...labelsProps}>
                    {labelsIcon}
                </IconButton>
            </Tooltip>}

            {showDiscrepancies && <Tooltip title="Label discrepancies">
                <IconButton aria-label="label-discrepancies" onClick={() => handleClick('discrepancies')} {...discrepanciesProps}>
                    {discrepanciesIcon}
                </IconButton>
            </Tooltip>}
        </Box>
    );
};

export default SortOptions;