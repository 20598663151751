import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

import StaticRegionImage from '../../components/StaticRegionImage';
import { CardContent, Divider, Tooltip, LinearProgress, Stack, Typography } from '@mui/material';

import UserAvatar from '../../components/UserAvatar';

const WeldItem = ({ showLabels, showLabelers, sample }) => {
    const title = `Weld #${sample.id}`;

    const date = new Date(sample.createdAt).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    let perc = 100;
    if ((sample.numBad + sample.numBad) > 0) {
        perc = sample.numGood / (sample.numGood + sample.numBad) * 100;
    }

    const mainColor = (sample.numGood + sample.numBad) > 0 ? "good" : "neutral";
    const goodColor = sample.numGood > 0 ? "goodBold" : "text.secondary";
    const badColor = sample.numBad > 0 ? "badBold" : "text.secondary";

    let cardContent;
    if (showLabels) {
        let goodNum = (
            <Typography ml={1} sx={{ fontWeight: 'bold' }}>
                {sample.numGood}
            </Typography>
        );
        let badNum = (
            <Typography ml={1} sx={{ fontWeight: 'bold' }}>
                {sample.numBad}
            </Typography>
        );

        if (showLabelers) {
            const goodLabelers = sample.labels
                .filter(sl => sl.label === 'good')
                .map(sl => sl.labeler.userName).join(', ');

            const badLabelers = sample.labels
                .filter(sl => sl.label === 'bad')
                .map(sl => sl.labeler.userName).join(', ');

            goodNum = (
                <Tooltip title={goodLabelers} placement="top">
                    {goodNum}
                </Tooltip>
            );

            badNum = (
                <Tooltip title={badLabelers} placement="top">
                    {badNum}
                </Tooltip>
            )
        }

        cardContent = (
            <>
                <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <Stack direction="row">
                        <CheckCircle color={goodColor} />
                        {goodNum}
                    </Stack>
                    <Stack direction="row">
                        <Cancel color={badColor} />
                        {badNum}
                    </Stack>
                </Stack>
                <LinearProgress
                    variant="determinate"
                    value={perc}
                    color={mainColor}
                    sx={{
                        marginTop: 2,
                        height: 6,
                        marginLeft: '-16px',
                        marginRight: '-16px',
                        marginBottom: '-24px',
                        backgroundColor: 'bad.main',
                    }}
                />
            </>
        );
    } else {
        cardContent = (
            <>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    pt="8px"
                    direction="row">
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Preliminary grading:
                    </Typography>
                    {sample.modelClassification === 'good' &&
                        <CheckCircle color="goodBold" />}
                    {sample.modelClassification === 'bad' &&
                        <Cancel color="badBold" />}
                </Stack>
            </>
        );
    }

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={title}
                subheader={date}
                avatar={
                    <UserAvatar user={sample.uploader} />
                }
            >
            </CardHeader>
            <CardMedia>
                <StaticRegionImage imgSrc={sample.imgUrl} region={sample.region} />
            </CardMedia>
            <CardContent>
                {cardContent}
            </CardContent>
        </Card>
    );
};

export default WeldItem;