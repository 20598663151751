import React, { useState } from 'react';

import axios from '../api';

import { withSnackbar } from './SnackbarHOC';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ReactRegionSelect from 'react-region-select';
import usePageTitle from '../features/navigation/PageTitle';

const ImageInference = (props) => {
  usePageTitle('New Weld');

  const [classification, setClassification] = useState("<none>");
  const [image, setImage] = useState();
  const [regions, setRegions] = useState([]);

  const imgElement = React.useRef(null);
  const fileInput = React.useRef();

  let imgSrc = "";
  if (image !== undefined) {
    imgSrc = URL.createObjectURL(image);
  }

  function handleChange(e) {
    const file = e.target.files[0];
    setImage(file);
    setClassification("<none>");
  }

  function handleRegionChange(regions) {
    regions[0].x = Math.max(regions[0].x, 0);
    regions[0].y = Math.max(regions[0].y, 0);

    regions[0].width = Math.min(regions[0].width, 100 - regions[0].x);
    regions[0].height = Math.min(regions[0].height, 100 - regions[0].y);

    setRegions(regions);
  }

  async function handleSubmitClick(e) {
    if (!image) {
      props.snackbarShowMessage('Please select an image.', 'warning');
      return;
    }

    if (regions.length !== 1) {
      props.snackbarShowMessage('Please select the weld region.', 'warning');
      return;
    }

    const selectedArea = (regions[0].width / 100.0) * (regions[0].height / 100.0);
    if (selectedArea < 0.1) {
      props.snackbarShowMessage('Please select a larger region.', 'warning');
      return;
    }

    setClassification('Loading...');

    const formData = new FormData();
    formData.append('image', image);
    formData.append('region_x', regions[0].x);
    formData.append('region_y', regions[0].y);
    formData.append('region_width', regions[0].width);
    formData.append('region_height', regions[0].height);

    const config = {
      headers: { 'Accept': 'multipart/form-data' },
    };

    try {
      const res = await axios().post('/api/samples/', formData, config);

      setClassification(res.data.classification);
    } catch (e) {
      props.snackbarShowMessage('Could not get classification', 'error');
      console.log('Inference error: ', e);
    }
  }

  const regionStyle = {
    background: 'rgba(0, 255, 0, 0.5)'
  };


  let imageComponent;
  if (image) {
    imageComponent = (
      <ReactRegionSelect
        maxRegions={1}
        regions={regions}
        regionStyle={regionStyle}
        onChange={handleRegionChange}
        style={{
          touchAction: "none",
          display: "flex"
        }}
        >
        <img
          id="imageDisplay"
          ref={imgElement}
          style={{
            maxHeight: "50vh",
            maxWidth: "80vw",
            touchAction: "none"
          }}
          sx={{
            m: 2
          }}
          src={imgSrc}
          alt="">
        </img>
      </ReactRegionSelect>
    );
  }
  else {
    imageComponent = <Skeleton variant="rectangular" width={256} height={256} />;
  }

  let classificationChip;
  if (classification === "good") {
    classificationChip = (
      <Chip
        color="primary"
        icon={<DoneIcon />}
        label="Good"
      />
    );
  } else if (classification === "bad") {
    classificationChip = (
      <Chip
        color="error"
        icon={<ClearIcon />}
        label="Bad"
      />
    );
  } else {
    classificationChip = (
      <Chip
        icon={<HelpOutlineOutlinedIcon />}
        label="Pending"
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
      }}>
      <Paper elevation={3} sx={{mb: 0.5}}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            textAlign="center"
            sx={{ m: 2 }}>
            Welding Image Classification
          </Typography>

          <Divider style={{ width: '100%' }} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInput.current.click()}
            sx={{ mt: 3, mb: 3 }}
          >
            Select File
          </Button>
          <input type="file" id="imageFile" capture="user" accept="image/*" onChange={handleChange} ref={fileInput} style={{ display: 'none' }}></input>

          {imageComponent}

          <Button onClick={handleSubmitClick} variant="contained" sx={{ mt: 3, mb: 3 }}>Submit</Button>

          <Divider style={{ width: '100%' }} />

          <Typography variant="body1" component="div" gutterBottom sx={{ m: 3 }}>
            Classification: {classificationChip}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default withSnackbar(ImageInference);
