import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { pageTitleUpdated } from "./navigationSlice";

const usePageTitle = (title) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pageTitleUpdated(title));
    });
};

export default usePageTitle;